import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { easeOut, linear } from 'popmotion'

import useUIContext from 'context/ui'

import { ScrollParallax } from 'components/motion/parallax'

import Rewind from 'assets/svg/shapes-rewind.inline.svg'
import Circle from 'assets/svg/shapes-circle.inline.svg'
import Square from 'assets/svg/shapes-square.inline.svg'

import s from './ShapesSection.module.scss'
const cn = classNames.bind(s)

const Line = () => <span />

const _ = {
  components: {
    Rewind,
    Circle,
    Square,
    Line,
  },
}

const Shape = ({ type, variation = '', onHover, keyframes = null, times = [0, 1], useEasing = false }) => {
  const scrollY = useUIContext((s) => s.scrollY)
  const Component = _.components[type]

  return (
    <div className={cn('shape', type.toLowerCase(), variation)} onMouseEnter={onHover || null}>
      <div className={cn('inner')}>
        {keyframes ? (
          <ScrollParallax motionValue={scrollY} keyframes={keyframes} times={times} ease={useEasing ? easeOut : linear}>
            <Component />
          </ScrollParallax>
        ) : (
          <Component />
        )}
      </div>
    </div>
  )
}

Shape.propTypes = {
  type: PropTypes.string,
  variation: PropTypes.string,
  onHover: PropTypes.func,
  keyframes: PropTypes.object,
  times: PropTypes.any,
  useEasing: PropTypes.bool,
}

export default Shape
