import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Layout from 'layouts'

import useUIContext from 'context/ui'

import { Row, Column12 } from 'components/ui/Grid'
import { Section } from 'components/layout'
import { Subtitle } from 'components/ui/Typography'
import BlogSection from 'components/ui/BlogSection'
import ImageSlice from 'components/ui/ImageSlice'
import InfoSection from 'components/ui/InfoSection'
import ParallaxImage from 'components/motion/ParallaxImage'
import RichTextSlice from 'components/ui/RichTextSlice'
import SEO from 'components/seo'
import Spacer from 'components/ui/Spacer'
import ThemeWaypoint from 'components/ui/ThemeWaypoint'
import VideoSlice from 'components/ui/VideoSlice'

import ShapesSection from '../home/components/ShapesSection'
import Quote from './components/Quote'

import s from './BlogPostPage.module.scss'
import { useEffectOnce } from 'react-use'

const cn = classNames.bind(s)

const ParallaxImageSlice = ({ data, ...props }) => <ParallaxImage image={data} {...props} />
const InfoSectionSlice = ({ ...props }) => <InfoSection contentFullWidth {...props} />

const componentsByType = {
  text: RichTextSlice,
  quote: Quote,
  video: VideoSlice,
  info_section: InfoSectionSlice,
  parallax_image: ParallaxImageSlice,
  image: ImageSlice,
}

const Slice = ({ type, data }) => componentsByType?.[type]?.({ data }) || null

Slice.propTypes = {
  type: PropTypes.oneOf(Object.keys(componentsByType)),
}

const BlogPostPage = ({ data }) => {
  const { page } = data.prismic

  /*
   * Reset initial state of `preventThemeChange` if user has navigated to BlogPostPage
   * from HomePage, since that section is using a custom page transition that needs to
   * take control of theme change temporarily.
   */
  const preventThemeChange = useUIContext((s) => s.setPreventThemeChange)
  const setPreventThemeChange = useUIContext((s) => s.setPreventThemeChange)
  useEffect(() => preventThemeChange && setPreventThemeChange(false), [])

  if (!page) return null
  const { page_meta_title, page_meta_description, page_meta_thumbnail, body, _meta, related_posts } = page

  /* Limit related to a maximum of 3 posts */
  const related = useMemo(() => {
    if (related_posts?.length > 3) {
      const arr = related_posts
      arr.length = 3
      return arr
    }

    return related_posts || []
  }, [related_posts])

  useEffectOnce(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <>
            <Layout lightTheme>
              <SEO
                title={page_meta_title}
                description={page_meta_description}
                thumbnail={page_meta_thumbnail}
                lightTheme
              />

              {body?.map(({ type, ...data }, index) => {
                return (
                  <Section isHidden={transitionStatus === 'entering'} key={index}>
                    <Spacer desktop="9vw" />
                    <Slice type={type} data={data} themeWaypoint={!index ? null : undefined} />
                  </Section>
                )
              })}

              <Row>
                <Column12 desktop={8} large={6} center className={cn('wrapper')}>
                  {!!_meta?.firstPublicationDate && (
                    <>
                      <Spacer initial="1rem" desktop="5vw" />
                      <Subtitle>{dayjs(_meta?.lastPublicationDate).format('DD MMM YYYY')}</Subtitle>
                    </>
                  )}

                  <Spacer desktop="9vw" />
                  <ThemeWaypoint after="dark" />

                  <ShapesSection />

                  <Spacer medium="0" />
                </Column12>
              </Row>

              {related?.length >= 1 && (
                <>
                  <Spacer initial="3rem" desktop="9vw" />
                  <BlogSection posts={related} title="Related articles" viewAllLabel="View all" reveal="mask" />
                  <Spacer medium="0" desktop="2.8vw" />
                </>
              )}
            </Layout>
          </>
        )
      }}
    </TransitionState>
  )
}

export default BlogPostPage
