import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { RichText } from 'prismic'

import { Subtitle } from 'components/ui/Typography'
import { Row, Column12 } from 'components/ui/Grid'

import QuoteSvg from 'assets/svg/quote.inline.svg'

import s from './Quote.module.scss'

const cn = classNames.bind(s)

const Quote = ({ data }) => {
  const { primary } = data

  if (!primary) return null

  return (
    <Row>
      <Column12 desktop={8} center>
        <div className={cn('inner')}>
          {primary.quote && (
            <div className={cn('quote')}>
              <QuoteSvg />
              <div className="headline2">{RichText.render(primary.quote)}</div>
            </div>
          )}

          {primary.author && (
            <div className={cn('author')}>
              <Subtitle>{primary.author}</Subtitle>
            </div>
          )}
        </div>
      </Column12>
    </Row>
  )
}

Quote.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Quote
