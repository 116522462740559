import { graphql } from 'gatsby'

import BlogPostPage from 'views/blog-post/BlogPostPage'

export const query = graphql`
  query BlogPostPageQuery($uid: String!) {
    prismic {
      page: blog_post(uid: $uid, lang: "en-us") {
        _meta {
          firstPublicationDate
        }

        page_meta_title
        page_meta_description
        page_meta_thumbnail

        blog_post_title
        thumbnail_description
        thumbnail

        body {
          ... on PRISMIC_Blog_postBodyInfo_section {
            type
            primary {
              title
              subtitle
              content
              link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
              }
              link_text
            }
          }

          ... on PRISMIC_Blog_postBodyText {
            type
            primary {
              text
            }
          }

          ... on PRISMIC_Blog_postBodyQuote {
            type
            label
            primary {
              author
              quote
            }
          }

          ... on PRISMIC_Blog_postBodyParallax_image {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 2000, srcSetBreakpoints: [375, 500, 1000, 1200, 1400]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }

          ... on PRISMIC_Blog_postBodyImage {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 2000, srcSetBreakpoints: [375, 500, 1000, 1200, 1400]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }

          ... on PRISMIC_Blog_postBodyVideo {
            type
            primary {
              embed_id
              embed_platform
              ratio
            }
          }
        }
        related_posts {
          blog_post {
            ...PrismicLinkFrag
            ... on PRISMIC_Blog_post {
              title: blog_post_title
              thumbnail_description
              thumbnail
              thumbnailSharp {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

BlogPostPage.query = query

export default BlogPostPage
