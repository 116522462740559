import React, { useRef } from 'react'
import classNames from 'classnames/bind'

import { Section } from 'components/layout'
import Shape from './Shape'
import {
  secondaryRed,
  secondaryGreen,
  secondaryBlue,
  secondaryTurqoise,
  secondaryYellow,
  text,
} from 'styles/config/_colors.scss'

import s from './ShapesSection.module.scss'
const cn = classNames.bind(s)

const _ = { colors: [secondaryRed, secondaryGreen, secondaryBlue, secondaryTurqoise, secondaryYellow, text] }
const getRandomColor = () => _.colors[Math.floor(Math.random() * (_.colors.length - 1))]

const ShapesSection = () => {
  const shapesRef = useRef()

  const onShapeHover = () => shapesRef.current.style.setProperty('--color-hover', getRandomColor())

  return (
    <Section className={cn('wrapper')}>
      <div className={cn('shapes')} ref={shapesRef}>
        <Shape type="Rewind" onHover={onShapeHover} keyframes={{ x: ['-10%', '20%'], y: ['-10%', '20%'] }} useEasing />
        <Shape type="Circle" onHover={onShapeHover} keyframes={{ x: ['-20%', '20%'] }} />
        <Shape type="Circle" variation="outline" onHover={onShapeHover} keyframes={{ rotate: [0, 360] }} />
        <Shape type="Square" onHover={onShapeHover} keyframes={{ x: ['50%', '0%'] }} times={[0, 0.8]} />
        <Shape
          type="Square"
          variation="rotated"
          onHover={onShapeHover}
          keyframes={{ rotate: [45, 0], y: ['-10%', '10%'] }}
          times={{ rotate: [0, 0.5] }}
        />
        <Shape
          type="Line"
          onHover={onShapeHover}
          keyframes={{ x: ['30%', '-10%'], scaleX: [0, 1] }}
          times={{ scaleX: [0, 0.5] }}
          useEasing
        />
      </div>
    </Section>
  )
}

export default ShapesSection
